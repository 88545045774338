

.hero-container {
  height: 80vh;
  position: relative;
  text-align: left;
  margin: 0 0 10px 0;
  background: var(--primary-light-color);
  clip-path: ellipse(100% 100% at 50% 0%);
}

.hero-content {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.hero-left {
  height: 100%;
  max-width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 0 0 100px;
}

.hero-left h1 {
  font-size: 3rem;
  color: var(--primary-color);
}

.highlight {
  color: var(--primary-color);
}

.hero-left p {
  font-size: 1.2rem;
  margin: 20px 0;
  color: #5c5c5c;
}

.cta-buttons {
  display: flex;
  gap: 20px;
}
.contact-info-email a{
  color: #007bff;
  font-weight: bold;  
  font-size: 32px;
}
.join-btn {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.watch-btn {
  background-color: transparent;
  color: var(--primary-color);
  border: 2px solid var(--primary-color);
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.hero-right {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-right img {
  height: 100%;
  width: auto;
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .hero-left h1 {
    font-size: 2.5rem;
  }
  
  .hero-left p {
    font-size: 1.1rem;
  }
}

@media (max-width: 992px) {
  .hero-container {
    clip-path: ellipse(90% 100% at 50% 0%);
  }

  .hero-left {
    padding: 0 0 0 50px;
  }
  
  .hero-left h1 {
    font-size: 2rem;
  }

  .hero-left p {
    font-size: 1rem;
  }

  .cta-buttons {
    flex-direction: column;
    gap: 10px;
  }
  
  .hero-right img {
    height: auto;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .hero-content {
    flex-direction: column;
    align-items: center;
  }
  
  .hero-left {
    max-width: 100%;
    align-items: center;
    padding: 0 20px;
  }

  .hero-right {
    display: none;
  }
}

@media (max-width: 576px) {
  .hero-left h1 {
    font-size: 1.5rem;
  }

  .hero-left p {
    font-size: 0.9rem;
  }

  .join-btn, .watch-btn {
    padding: 8px 16px;
  }
}


