.about-container {
  max-width: 800px;
  margin: 0 auto;
  padding-top: 90px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

.about-container h1 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #333;
}

.about-container h2 {
  font-size: 1.5em;
  margin-top: 40px;
  margin-bottom: 20px;
  color: #333;
}

.about-container p {
  margin-bottom: 20px;
  color: #666;
}

.about-container p:last-child {
  margin-bottom: 0;
}
