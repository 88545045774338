.form-container {
    
   padding-top: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
}

.form-title {
    font-size: 30px;
    color: var(--primary-color);
    margin-bottom: 15px;
}

.form {
    width: 500px;
}

.form-group {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.form-label {
    margin: 5px;
    font-size: 15px;
    font-weight: 500;
    color: var(--secodnary-color);
}

.form-input {
    width: 100%;
    border-radius: 10px;
    border: 1px solid var(--gray-color);
    font-size: 21px;
    padding: 10px;
}

.form-btn {
    width: 100%;
    text-align: center;
    cursor: pointer;
    background-color: var(--primary-color);
    border: none;
    font-size: 21px;
    font-weight: 500;
    color: var(--white-color);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 15px;
}

.form-footer {
    margin: 10px 0;
    font-size: 17px;
    color: var(--dark-color);
}

.google_btn {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: none;
    outline: none;
    background-color: white;
    box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 20px 0;
    color: #2c444e;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    position: relative;
}

.google_btn > img{
    width: 30px;
    height: 30px;
    object-fit: cover;
}

.google_btn > span{
    margin-left: 10px;
}

/* Media Queries */
@media (max-width: 520px) {
    .form {
        width: 100%;
    }
}
