.loader-container {
  position: fixed;
  top: 50%;
  left: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease-in-out;
}

[dir="rtl"] .loader-container {
  position: fixed;
  top: 90%;
  left: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease-in-out;
}
.circle {
  background-color: #007bff;
  padding: 10px;
  border-radius: 50%;
  margin: 5px;
  animation: bounce 0.5s infinite;
}
.circle:nth-last-of-type(2) {
  animation-delay: 0.1s;
}
.circle:nth-last-of-type(3) {
  animation-delay: 0.2s;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}
.loading-spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  box-shadow: #007bff -1px 0px 104px 7px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
