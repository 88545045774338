/* General RoadMap container */
.roadmap-container {
    font-family: Arial, sans-serif;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    color: #333;
  }
  
  /* Title styling */
  .roadmap-title {
    text-align: center;
    font-size: 2.5rem;
    color: #444;
    margin-bottom: 40px;
  }
  
  /* Step section styling */
  .step-section h2, 
  .next-steps-section h2 {
    font-size: 1.8rem;
    color: #222;
    margin-bottom: 20px;
  }
  
  /* Phases styling */
  .phase {
    margin-bottom: 30px;
  }
  .phase img{
    height: 350px;
    border-radius: 12px;
  }
  
  .phase-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .phase-title.blue {
    color: #007bff;
  }
  
  .phase-title.yellow {
    color: #ffc107;
  }
  
  p {
    font-size: 1.2rem;
    line-height: 1.6;
  }
  
  /* Next Steps Section */
  .next-steps-section ul {
    list-style-type: none;
    padding: 0;
  }
  
  .next-steps-section li {
    font-size: 1.2rem;
    margin-bottom: 15px;
  }
  
  .certifications {
    margin-left: 20px;
  }
  
  .certifications li {
    font-size: 1.1rem;
    color: #555;
  }
  