.mid-content-container {
  margin: 0 auto;
  padding-top: 100px;
}

.companies {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.companies img{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* ***********
   **       **
   ***********
 */
.center-headded-section {
  text-align: center;
  padding: 2rem;
}
.cloud-top {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 500px;
  margin: 0 auto;
  padding-top: 100px;
}
.cloud-software-title {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  color: var(--primary-color);
}

.cloud-software-title span {
  color: var(--secondary-color);
}

.cloud-software-description {
  font-size: 1rem;
  line-height: 2;
  margin-bottom: 2rem;
}

.features {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.feature {
  background-color: white;
  box-shadow: 0 0px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 1.5rem;
  width: 300px;
  height: 400px;
  margin: 1rem;
  text-align: center;
}

.feature .icon img {
  height: 90px;
  margin-bottom: 1rem;
}

.feature h3 {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.feature p {
  font-size: 1rem;
  color: #555;
}
/* ***********
   **       **
   ***********
 */
.slides {
  display: flex;
  gap: 70px;
  overflow-x: auto;
  padding: 20px;
  align-items: center;
  justify-content: center;
}

.slide-card {
  height: 300px;
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  transition: transform 0.5s ease;
  cursor: pointer;
  z-index: 0;
  color: white;
}

.slide-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
  transition: background 0.5s ease;
}

.card1 {
  background-image: url("../../public/instructor.jpg");
  background-size: cover;
  background-position: center;
}

.card2 {
  background-image: url("../../public/students.jpg");
  background-size: cover;
  background-position: center;
}

.slide-card:hover::before {
  background: rgba(0, 0, 0, 0.3); /* Change overlay transparency on hover */
}

.slide-card h3,
.slide-card p {
  position: relative;
  z-index: 2;
  padding: 20px;
}

.slide-card h3 {
  margin-top: 0;
  font-size: 1.5rem;
}

.slide-card p {
  font-size: 1rem;
}

/* ***********
   **       **
   ***********
 */
.classroom-section {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 10rem;
  background-color: #fff;
}

.classroom-content {
  max-width: 40%;
  padding: 0 64px 0 20px;
}

.classroom-content h2 {
  font-size: 1.5rem;
  line-height: 1.2;
  color: var(--primary-color);
}
.classroom-content .highlight {
  position: relative;
  color: var(--secondary-color);
  font-weight: bold;
  z-index: 10;
}

.classroom-content .highlight::before {
  position: absolute;
  content: "";
  width: 40px;
  height: 40px;
  top: -10px;
  left: -10px;
  display: flex;
  background-color: var(--primary-color);
  margin-right: 10px;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  z-index: -1;
}

.classroom-content p {
  font-size: 1rem;
  color: #555;
  margin: 1rem 0;
}

.classroom-content .learn-more {
  display: inline-block;
  margin-top: 1rem;
  font-size: 1rem;
  color: #333;
  text-decoration: underline;
}

.classroom-image-container {
  position: relative;
  max-width: 50%;
  z-index: 3;
}
.classroom-image-container::after {
  position: absolute;
  content: "";
  width: 1020px;
  height: 800px;
  top: -10px;
  left: -10px;
  display: flex;
  background-color: var(--primary-color);
  margin-right: 10px;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  z-index: -1;
}
.classroom-image-container::before {
  position: absolute;
  content: "";
  width: 127px;
  height: 101px;
  bottom: -9px;
  right: -18px;
  display: flex;
  background-color: var(--secondary-color);
  margin-right: 10px;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  z-index: -1;
}

.classroom-image {
  width: 100%;
  border-radius: 10px;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  padding: 1rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.play-button svg {
  fill: #ff7a00; /* Orange color */
}

/* ***********
   **       **
   ***********
 */
.features- {
  display: flex;
  gap: 70px;
  overflow-x: auto;
  padding: 20px;
  align-items: center;
  justify-content: center;
}
.feature-1 {
  width: 60%;
}
.feature-1 {
  background-image: url("../../public/assets/onlinecall.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.features-icons {
    width: 70%;
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 0 0px;
}
.feature--card {
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  transition: transform 0.5s ease;
  cursor: pointer;
}
.feature-2 {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 40%;
}
.feature-2 h3 {
  color: var(--secondary-color);
  text-align: start;
  width: 40%;
  line-height: 1.5;
}
.feature-2 span {
  color: var(--primary-color);
}
.feature-2 .feature-icon img {
  height: 25px;
  width: 25px;
}
.feature- {
  display: flex;
  gap: 15px;
}
.feature--card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.feature-text p {
  font-size: 1rem;
  color: #555;
  text-align: start;
}

/* ***********
   **       **
   ***********
 */

/* Responsive Styles */
@media (max-width: 1200px) {
  .cloud-software-title {
    font-size: 1.75rem;
  }

  .cloud-software-description {
    font-size: 0.9rem;
  }

  .slide-card {
    height: 250px;
    width: 400px;
  }

  .classroom-content h2 {
    font-size: 1.25rem;
  }

  .classroom-content p {
    font-size: 0.9rem;
  }

  .classroom-image-container::after {
    width: 800px;
    height: 600px;
  }

  .classroom-image-container::before {
    width: 100px;
    height: 80px;
  }
}

@media (max-width: 992px) {
  .classroom-section {
    flex-direction: column;
    align-items: center;
    padding: 2rem;
  }

  .classroom-content {
    max-width: 80%;
    padding: 0;
    text-align: center;
  }

  .classroom-image-container {
    max-width: 100%;
    margin-top: 2rem;
  }

  .features {
    flex-direction: column;
  }

  .feature {
    width: 90%;
    height: auto;
  }
}

@media (max-width: 768px) {
  .center-headded-section {
    padding: 1rem;
  }

  .features- {
    flex-direction: column;
    gap: 20px;
  }

  .feature-1 {
    width: 100%;
    height: 200px;
  }

  .features-icons {
    width: 100%;
    gap: 10px;
  }

  .feature-2 h3 {
    width: 100%;
    font-size: 1rem;
  }

  .feature-2 {
    width: 100%;
  }

  .slide-card {
    height: 200px;
    width: 300px;
  }
}

@media (max-width: 576px) {
  .cloud-top {
    padding-top: 50px;
  }

  .cloud-software-title {
    font-size: 1.5rem;
  }

  .cloud-software-description {
    font-size: 0.8rem;
  }

  .slide-card {
    height: 150px;
    width: 250px;
  }

  .classroom-content h2 {
    font-size: 1rem;
  }

  .classroom-content p {
    font-size: 0.8rem;
  }

  .play-button {
    padding: 0.5rem;
  }
  .slides {
 flex-direction: column;
}
}

/* ***********
   **       **
   ***********
 */

/* ***********
   **       **
   ***********
 */

/* ***********
   **       **
   ***********
 */

/* ***********
   **       **
   ***********
 */
