.contact-us-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
  }
  
  .contact-us-title {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .contact-us-paragraph {
    margin-bottom: 20px;
    color: #555;
  }
  
  .contact-us-section {
    margin-top: 40px;
  }
  
  .contact-us-heading {
    color: #0056b3;
    margin-bottom: 10px;
  }
  
  .contact-info {
    margin-top: 50px;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
  }
  
  .contact-info-title {
    color: #333;
  }
  
  .contact-info-email {
    color: #007bff;
    font-weight: bold;
  }
  