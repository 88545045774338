@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

:root {
--primary-color:#F48C06;
--primary-light-color:#fae6cb;
--secondary-color:#2F327D;
--mid-color:#65DAFF;
--font-color:#252641;
--gray-color:#a4a4a4;
--dark-color:#364fc7;
--light-color:#dbe4ff;
--v-light-color:#edf2ff;
--border-color:#4a4a4a;
}

*{
  padding: 0;
  margin: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
}
body{
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}
.main-container{
    /* max-width: 1200px; */
  margin: 0 auto;
}
/* .homepage-container{
  padding-top: 50px;
} */
.rel{position: relative;}
.abs{position: absolute;}
.fixed{position: fixed;}
.df{display: flex;}
.bl{display: block;}
.aic{align-items: center;}
.fdc{flex-direction: column;}
.jcsb{justify-content: space-between;}
.jcc{justify-content: center;}
