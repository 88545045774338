.footer {
  background-color: #1c1c28;
  color: #ffffff;
  text-align: center;
  padding: 40px 20px;
}

.footer-top {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
}

.logo span {
  margin-left: 10px;
  font-size: 18px;
  color: #8888a0;
}

.newsletter {
  margin-bottom: 20px;
}

.newsletter h3 {
  margin-bottom: 10px;
}

.newsletter-form {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}


.subscribe-button {
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  background: linear-gradient(90deg, #667eea, #764ba2);
  color: white;
  cursor: pointer;
  transition: background 0.3s ease;
}

.subscribe-button:hover {
  background: linear-gradient(90deg, #5a67d8, #6b46c1);
}

.footer-bottom {
  margin-top: 20px;
}

.links {
  list-style: none;
  padding: 0;
  margin: 10px 0;
  display: flex;
  justify-content: center;
  gap: 15px;
}

.links li {
  color: #8888a0;
}

.links li a {
  color: #8888a0;
  text-decoration: none;
  transition: color 0.3s ease;
}

.links li a:hover {
  color: #ffffff;
}
