.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--primary-light-color);
  color: var(--font-color);
  padding: 0px 100px;
  margin: 0 auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
}

.logo {
  height: 100%;
}

.logo img {
  width: 100px;
  height: 100px;
  transition: transform 0.3s ease; /* Smooth hover effect */
}

.logo img:hover {
  transform: scale(1.1); /* Enlarge the logo on hover */
}

.title {
  font-size: 2.5rem; /* Larger, modern font size */
  margin-bottom: 0.5rem;
  font-weight: 700; /* Bold style */
  letter-spacing: 1px;
}

.nav-links {
  display: flex;
  gap: 2.5rem; /* Larger gap between links */
}

.nav-links a {
  color: var(--font-color);
  font-size: 16px; /* Larger font size */
  font-weight: 700; /* Bold style */
  transition: color 0.3s ease, transform 0.3s ease; /* Smooth hover animation */
  text-transform: uppercase; /* Modern style */
}

.nav-links a:hover {
  color: #1377fa;
  transform: translateY(-5px); /* Elevate link on hover */
}

.navLink {
  color: white;
  text-decoration: none;
}

.navLink:hover {
  text-decoration: underline;
}

.btn {
  padding: 12px 24px; /* Larger padding for buttons */
  border: none;
  border-radius: 30px; /* More rounded buttons */
  cursor: pointer;
  font-weight: 600; /* Bolder font weight */
  font-size: 14px;
  transition: background-color 0.3s ease, transform 0.3s ease; /* Animation on hover */
}

.btn:hover {
  transform: scale(1.05); /* Enlarge button on hover */
}

.login {
  background-color: #fff;
  margin-right: 1rem;
  color: var(--font-color);
}

.signup {
  background-color: var(--primary-color);
  color: white;
}

.header-right-user-info {
  display: flex;
  align-items: center;
  gap: 12px; /* Slightly larger gap */
}

.header-right-user-photo {
  width: 50px; /* Larger user photo */
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.header-right-user-photo:hover {
  transform: scale(1.1); /* Enlarge photo on hover */
}

.join {
  background-color: var(--primary-color);
  color: white;
  padding: 12px 24px;
  border-radius: 30px;
  font-size: 14px;
  font-weight: 700; /* Bolder font */
  margin-right: 1rem;
  cursor: pointer;
  border: none;
  margin-top: 10px;
  transition: background-color 0.3s ease, transform 0.3s ease; /* Animation on hover */
}
.join a{
  color: white;
}
.join:hover {
  background-color: #1377fa;
  transform: scale(1.05); /* Slightly enlarge on hover */
}

/* Burger icon styles */
.nav-links-mobile {
  display: none; /* Hide on desktop */
  cursor: pointer;
}

.burger-icon {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.burger-icon .line {
  width: 25px;
  height: 3px;
  background-color: var(--font-color);
  transition: all 0.3s ease;
}

.burger-icon.open .line:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.burger-icon.open .line:nth-child(2) {
  opacity: 0;
}

.burger-icon.open .line:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

/* Hide nav links initially on mobile */
.nav-links {
  display: flex;
  gap: 2.5rem;
}

@media (max-width: 768px) {
  .nav-links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 90px;
    right: 0;
    background-color: var(--primary-light-color);
    width: 100%;
    height: calc(100vh - 60px);
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 2rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transform: translateY(-100%);
    transition: transform 0.3s ease;
    z-index: 9999;
  }

  .nav-links.open {
    display: flex;
    transform: translateY(0);
  }
.header{
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
}
  .nav-links-mobile {
    display: block;
  }

  .join {
    display: none; /* Hide the "Join Now" button on mobile */
  }
}
